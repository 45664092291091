@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap);
/*=============== GOOGLE FONTS ===============*/

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  --hue: 174;
  --sat: 63%;
  --first-color: hsl(var(--hue), var(--sat), 40%);
  --first-color-alt: hsl(var(--hue), var(--sat), 36%);
  --title-color: hsl(var(--hue), 12%, 15%);
  --text-color: hsl(var(--hue), 8%, 35%);
  --body-color: hsl(var(--hue), 100%, 99%);
  /* --container-color: #FFF; */

  /*========== Font and typography ==========*/
  --body-font: 'Open Sans', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --tiny-font-size: .625rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --h1-font-size: 2.25rem;
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body-font);
  font-size: .938rem;
  font-size: var(--normal-font-size);
  background-color: hsl(174, 100%, 99%);
  background-color: var(--body-color);
  color: hsl(174, 8%, 35%);
  color: var(--text-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 4.5rem 0 2rem;
}

.section__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
  color: hsl(174, 12%, 15%);
  color: var(--title-color);
  text-align: center;
  margin-bottom: 1.5rem;
}

.section__height {
  height: 100vh;
}

/*=============== LAYOUT ===============*/
.container {
  max-width: 968px;
  margin-left: 1rem;
  margin-right: 1rem;
}

/*=============== HEADER ===============*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--container-color);
  z-index: 100;
  z-index: var(--z-fixed);
  transition: .4s;
}

/*=============== NAV ===============*/
.nav {
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__img {
  width: 32px;
  border-radius: 50%;
}

.nav__logo {
  color: hsl(174, 12%, 15%);
  color: var(--title-color);
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -1px 12px hsla(174, 63%, 15%, 0.15);
    box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    display: grid;
    align-content: center;
    border-radius: 1.25rem 1.25rem 0 0;
    transition: .4s;
  }

  .footer {
    padding-bottom: 20rem;
  }
}
.nav__list, 
.nav__link {
  display: flex;
}

.nav__link {
  flex-direction: column;
  align-items: center;
  grid-row-gap: 4px;
  row-gap: 4px;
  color: hsl(174, 12%, 15%);
  color: var(--title-color);
  font-weight: 600;
}

#icon{
    display: none;
}

.nav__list {
  justify-content: space-around;
}

.nav__name {
  font-size: .625rem;
  font-size: var(--tiny-font-size);
  /* display: none;*/ /* Minimalist design, hidden labels */
}

.nav__icon {
  font-size: 1.5rem;
}

/*Active link*/
.active-link {
  position: relative;
  color: hsl(174, 63%, 40%);
  color: var(--first-color);
  transition: .3s;
}

/* Minimalist design, active link */
/* .active-link::before{
  content: '';
  position: absolute;
  bottom: -.5rem;
  width: 4px;
  height: 4px;
  background-color: var(--first-color);
  border-radius: 50%;
} */

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 12px hsla(174, 63%, 15%, 0.15);
  box-shadow: 0 1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
}

/*=============== HOME ===============*/
#home{
  margin-top: 4.5rem;
}
section.home__hero div{
    margin: 2.5rem 0;
}
div.hero__content h1 span{
  font-family: 'Lato', sans-serif;
    font-size: 38px;
}

div.hero__message p{
  font-family: 'DM Sans', sans-serif;
}

div.hero__cta{
    display: flex;
    justify-content: space-between;
}

div.hero__cta a{
  font-size: 14px;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  grid-gap: 0.6em;
  gap: 0.6em;
  padding: 0.75em 1em;
  background-color: rgb(25, 25, 25);
  color: rgb(255, 255, 255);
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: rgb(25, 25, 25);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

div.hero__social{
    display: flex;
    justify-content: center;
}

.hero__gradient{
  background: linear-gradient(90deg, rgb(255, 116, 80), rgb(123, 97, 255), rgb(255, 116, 80)) 0% 0% / 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/*=============== SKILLS ===============*/
.skills__intro{
  text-align: center;
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 2rem;
}
.skills__items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1em;
    gap: 1em;
    border-radius: 6px;
    /* color: rgb(255, 255, 255); */
    padding: 20px 0;
    flex: 1 1;
    max-width: 400px;
}

div.skills__items .item .head{
  margin: 1rem 0;
}
div.skills__items .item .head h3{
  display: flex;
  align-items: center;
}
div.skills__items .item .head svg{
  margin-right: 0.5rem;
}
div.skills__items .item p{
  font-family: 'DM Sans', sans-serif;
}

/*=============== SKILLS BAR ===============*/
div.skill__box{
  display: flex;
  margin: auto;
  margin-top: 2.5rem;
}

div.skill__box svg{
  margin: auto .5rem;
}

.CircularProgressbar-path {
  stroke: #808080 !important;
}

/*=============== ABOUT ===============*/
section.about__content{
  /* display: flex; */
}

section.about__content h3, h4{
  text-align: center;
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  margin: 2rem 0;
}

section.about__content .hero__message__second{
  margin-bottom: 2.5rem;
}

div.about__cv{
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.about__cv a{
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    font-size: 14px;
    padding: 0.75em 1em;
    background-color: rgb(25, 25, 25);
    color: rgb(255, 255, 255);
    border-radius: var(--border-radius);
    border-style: solid;
    border-color: rgb(25, 25, 25);
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
}

/*=============== CONTACT ===============*/
#contact{
  margin-top: 2.5rem;
}
section.contact__content h5{
    text-align: center;
    font-size: 36px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 2rem;
}

section.contact__content .contact__social{
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.5rem;
}

section.contact__content .contact__social a{
  font-size: 38px;
  color: hsl(174, 8%, 35%);
  color: var(--text-color);
}

/*=============== SCROLL TO TOP ===============*/
.top-to-btm, .top-to-btm-hide{
  display: none;
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */
/* Remove if you choose, the minimalist design */
@media screen and (max-width: 320px) {
  .nav__name {
    display: none;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .nav__list {
    justify-content: center;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  div.skills__items{
    max-width: 100%;
    flex-wrap: wrap;
    flex-direction: row;

  }
  div.skills__items .item{
    /* margin: 0 7rem; */
    max-width: 40%;
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }
  .section {
    padding: 7rem 0 2rem;
  }
  .nav {
    height: calc(3rem + 1.5rem);
    height: calc(var(--header-height) + 1.5rem);
  }
  .nav__img {
    display: none;
  }
  .nav__icon {
    display: none;
  }
  .nav__name {
    font-size: .938rem;
    font-size: var(--normal-font-size);
    /* display: block; */ /* Minimalist design, visible labels */
  }
  .nav__link:hover {
    color: hsl(174, 63%, 40%);
    color: var(--first-color);
  }
  #home{
    margin-top: 7rem;
  }
  div.hero__cta{
    justify-content: center;
  }
  div.hero__cta a{
    margin: 0 2rem;
    font-size: 14px;
  }
  div.hero__cta a:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  div.about__cv a:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  section.skills_hero{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
  }
  div.skills__items{
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
  }
  div.skills__items .item{
    /* margin: 0 7rem; */
    max-width: 30%;
  }

  div.skill__box svg{
    margin: auto 2.5rem;
  }

  section.contact__content .contact__social{
    justify-content: center;
  }

  section.contact__content .contact__social a{
    margin: 0 1.5rem;
  }

  section.about__content .hero__message{
    max-width: 80%;
    margin: auto;
  }

  .about__messages{
    display: flex;
  }

  .about__message{
    max-width: 50%;
  }

  /* First design, remove if you choose the minimalist design */
  .active-link::before {
    content: '';
    position: absolute;
    bottom: -.75rem;
    width: 4px;
    height: 4px;
    background-color: hsl(174, 63%, 40%);
    background-color: var(--first-color);
    border-radius: 50%;
  }

  /* Minimalist design */
  /* .active-link::before{
      bottom: -.75rem;
  } */
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .header{
    position: initial;
  }

  .header-bg-scroll {
    background-color: #fff !important;
}

.top-to-btm{
  position: relative;
  display: block;
}
.top-to-btm-hide{
  display: none;
}
.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style{
  background-color: #551B54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  -webkit-animation: movebtn 3s ease-in-out infinite;
          animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  -webkit-animation: none;
          animation: none;
  background: #fff;
  color: #551B54;
  border: 2px solid #551B54;
}
@-webkit-keyframes movebtn {
  0%{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  25%{
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  50%{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  75%{
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100%{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes movebtn {
  0%{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  25%{
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  50%{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  75%{
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100%{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

  div.skill__box {
    width: 75%;
  }
}

/*=============== EXTRA ===============*/
.header-bg-scroll{
  background-color: #000;
}
.header-bg-scroll a{
  color: #fff;
}
.header-bg-scroll .nav__icon{
  color: #fff;
}














.footer {
  margin-top: 5rem;
  width: 100%;
  background-color: #25262a;
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.foot-1 {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-gap: 40px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid;
}

.title {
  color: #eee;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 12px;
}

p{
  font-size: 0.85em;
  line-height: 22px;
  cursor: pointer;
}

.foot-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socials a {
  text-decoration: none;
  color: #fff;
  margin: 8px;
  background-color: #33353b;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.socials a:hover i {
  color: #eee;
}

li,
.socials i {
  transition: color 0.3s;
}

/* ---------   Responsiveness   -------------- */

@media (max-width: 600px) {

  .footer {
      padding: 24px;
  }

  .foot-1 {
      grid-template-columns: 1fr 1fr;
  }

  .about {
      grid-column: 1 / 3;
  }

  .foot-2 {
      flex-direction: column;
  }

  .socials {
      margin-top: 16px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding-bottom: 5rem;
  }
}
